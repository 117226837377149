window.addEventListener('load', function () {
  const Swiper = window.Swiper;
  var productCarousel = document.querySelector('.products-row-swiper');
  if (productCarousel) {
    new Swiper('.products-row-swiper', {
      slidesPerView: 1,
      spaceBetween: 0,
      centerInsufficientSlides: true,
      breakpoints: {
        600: {
          slidesPerView: 2,
          slidesPerGroup: 2,
        },
        960: {
          slidesPerView: 4,
        },
        1200: {
          slidesPerView: 6,
        },
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.products-row__navigation .swiper-button-next',
        prevEl: '.products-row__navigation .swiper-button-prev',
      },
    });
  }
});
